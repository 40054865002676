export default function getFilterChoices() {
  return {
    consumer: {
      gender: [
        { value: 'M', text: 'Male' },
        { value: 'F', text: 'Female' },
      ],
      marital_status: [
        { value: 'M', text: 'Married' },
        { value: 'S', text: 'Single' },
        { value: 'A', text: 'Inferred Married' },
        { value: 'B', text: 'Inferred Single' },
      ],
      homeowner_status: [
        { value: 'O', text: 'Home Owner' },
        { value: 'R', text: 'Renter' },
      ],
      age_range: [
        { value: 'A', text: '18 to 25' },
        { value: 'B', text: '26 to 39' },
        { value: 'C', text: '40 to 49' },
        { value: 'D', text: '50 to 63' },
        { value: 'E', text: '64 to 65' },
        { value: 'F', text: '66 to 69' },
        { value: 'G', text: '70 to 74' },
        { value: 'H', text: '75 to 80' },
        { value: 'I', text: 'Over 80' },
      ],
      year_of_birth: Array(101).fill().map((_, i) => i + new Date().getFullYear() - 100),
      month_of_birth: [
        { value: 1, text: 'January' },
        { value: 2, text: 'February' },
        { value: 3, text: 'March' },
        { value: 4, text: 'April' },
        { value: 5, text: 'May' },
        { value: 6, text: 'June' },
        { value: 7, text: 'July' },
        { value: 8, text: 'August' },
        { value: 9, text: 'September' },
        { value: 10, text: 'October' },
        { value: 11, text: 'November' },
        { value: 12, text: 'December' },
      ],
      day_of_birth: Array(31).fill().map((_, i) => i + 1),
      net_worth: [
        { value: 'A', text: 'Less than $1' },
        { value: 'B', text: '$1–$4,999' },
        { value: 'C', text: '$5,000–$9,999' },
        { value: 'D', text: '$10,000–$24,999' },
        { value: 'E', text: '$25,000–$49,999' },
        { value: 'F', text: '$50,000–$99,999' },
        { value: 'G', text: '$100,000–$249,999' },
        { value: 'H', text: '$250,000–$499,999' },
        { value: 'I', text: 'Greater than $499,999' },
      ],
      income: [
        { value: 'A', text: 'Up to $10,000' },
        { value: 'B', text: '$10,000 to $14,999' },
        { value: 'C', text: '$15,000 to $19,999' },
        { value: 'D', text: '$20,000 to $24,999' },
        { value: 'E', text: '$25,000 to $29,999' },
        { value: 'F', text: '$30,000 to $34,999' },
        { value: 'G', text: '$35,000 to $39,999' },
        { value: 'H', text: '$40,000 to $44,999' },
        { value: 'I', text: '$45,000 to $49,999' },
        { value: 'J', text: '$50,000 to $54,999' },
        { value: 'K', text: '$55,000 to $59,999' },
        { value: 'L', text: '$60,000 to $64,999' },
        { value: 'M', text: '$65,000 to $74,999' },
        { value: 'N', text: '$75,000 to $99,999' },
        { value: 'O', text: '$100,000 to $149,999' },
        { value: 'P', text: '$150,000 to $174,999' },
        { value: 'Q', text: '$175,000 to $199,999' },
        { value: 'R', text: '$200,000 to $249,999' },
        { value: 'S', text: '$250,000 +' },
      ],
      credit_rating: [
        { value: 'A', text: '800+' },
        { value: 'B', text: '750–799' },
        { value: 'C', text: '700–749' },
        { value: 'D', text: '650–699' },
        { value: 'E', text: '600–649' },
        { value: 'F', text: '550–599' },
        { value: 'G', text: '500–549' },
        { value: 'H', text: 'Under 499' },
      ],
      dwell_type: [
        { value: 'S', text: 'Single Family Dwelling Unit' },
        { value: 'M', text: 'Multiple Family Dwelling Unit' },
      ],
      phone_number: [
        { value: 'Y', text: 'Yes' },
        { value: 'N', text: 'No' },
      ],
      email_address: [
        { value: 'Y', text: 'Yes' },
        { value: 'N', text: 'No' },
      ],
      ethnic_group: [
        { value: 'O', text: 'Far Eastern' },
        { value: 'W', text: 'Western European' },
        { value: 'F', text: 'All African American Ethnic Groups' },
        { value: 'M', text: 'Mediterranean' },
        { value: 'Y', text: 'Hispanic' },
        { value: 'S', text: 'Scandinavian' },
        { value: 'N', text: 'Native American' },
        { value: 'E', text: 'Eastern European' },
        { value: 'P', text: 'Polynesian' },
        { value: 'J', text: 'Jewish' },
        { value: 'A', text: 'Southeast Asian' },
        { value: 'C', text: 'Central & Southwest Asian' },
        { value: 'T', text: 'Other' },
      ],
      religion: [
        { value: 'B', text: 'Buddhist' },
        { value: 'P', text: 'Protestant' },
        { value: 'C', text: 'Catholic' },
        { value: 'S', text: 'Shinto' },
        { value: 'I', text: 'Islamic' },
        { value: 'O', text: 'Eastern Orthodox' },
        { value: 'J', text: 'Jewish' },
        { value: 'H', text: 'Hindu' },
        { value: 'M', text: 'Mormon' },
        { value: 'L', text: 'Lutheran' },
        { value: 'K', text: 'Sikh' },
      ],
      children: [
        { value: 'Y', text: 'Yes' },
        { value: 'N', text: 'No' },
      ],
    },
    business: {
      sales: [
        { value: 'A', text: 'Less than $5 million' },
        { value: 'B', text: '$5 to 10 million' },
        { value: 'C', text: '$10 to 20 million' },
        { value: 'D', text: '$20 to 50 million' },
        { value: 'E', text: '$50 to 100 million' },
        { value: 'F', text: 'Over $100 million' },
      ],
      employees: [
        { value: 'A', text: '1 to 49' },
        { value: 'B', text: '50 to 99' },
        { value: 'C', text: '100 to 249' },
        { value: 'D', text: '250 to 499' },
        { value: 'E', text: 'Over 500' },
      ],
      email_address: [
        { value: 'Y', text: 'Yes' },
      ],
    },
  };
}

export function getFilterChoicesMap() {
  const filterChoices = getFilterChoices();
  const filterChoicesMap = {};

  Object.entries(filterChoices).forEach(([searchType, filters]) => {
    filterChoicesMap[searchType] = {};

    Object.entries(filters).forEach(([filterKey, allFilterData]) => {
      if (!['year_of_birth', 'day_of_birth'].includes(filterKey)) {
        filterChoicesMap[searchType][filterKey] = {};

        allFilterData.forEach((filterData) => {
          filterChoicesMap[searchType][filterKey][filterData.value] = filterData.text;
        });
      }
    });
  });

  return filterChoicesMap;
}
