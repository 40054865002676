<template>
  <div>
    <v-container>
      <v-row>
        <v-col
          cols="12"
          class="d-flex justify-center"
        >
          <v-card
            max-width="900"
            width="900"
            class="pa-5"
            flat
          >
            <h1 class="title mb-3">
              <template v-if="savedSearchId === null">
                New Search
              </template>

              <template v-else>
                Saved Search

                <template v-if="savedSearchStatus === 'loaded'">
                  -
                  {{ savedSearchData.search_name }}
                </template>
              </template>

              <template v-if="savedSearchId === null || savedSearchStatus === 'loaded'">
                <v-btn
                  small
                  :disabled="!step1Complete"
                  class="mr-2"
                  @click.stop="savedSearchDialogShowing = true"
                >
                  <template v-if="savedSearchId === null">
                    Save This Search
                  </template>

                  <template
                    v-else-if="savedSearchData.original_json === JSON.stringify(getSearchJson())"
                  >
                    Rename
                  </template>

                  <template v-else>
                    Save Changes
                  </template>
                </v-btn>

                <v-btn
                  small
                  :disabled="!step1Complete"
                  @click.stop="newSearch"
                >
                  <template v-if="savedSearchId === null">
                    Start Over
                  </template>

                  <template v-else>
                    Start a New Search
                  </template>
                </v-btn>
              </template>
            </h1>

            <template v-if="savedSearchId === null || savedSearchStatus === 'loaded'">
              <h2 class="text-h6 mb-3">
                Leads Found:

                <v-progress-circular
                  v-if="gettingLeadCount"
                  indeterminate
                  :width="1"
                  :size="16"
                />

                <template v-else-if="leadCount === null">
                  Please select the type of search and also the state
                </template>

                <template v-else>
                  {{ formatNumber(leadCount) }}

                  <v-btn
                    color="primary"
                    small
                    :disabled="leadCountConditions === JSON.stringify(getSearchJson())"
                    @click="getLeadCount"
                  >
                    Update Count
                  </v-btn>
                </template>
              </h2>

              <v-alert
                v-if="formErrors.non_field_errors"
                dense
                border="left"
                type="warning"
              >
                {{ formErrors.non_field_errors[0] }}
              </v-alert>

              <v-stepper v-model="step">
                <v-stepper-header>
                  <v-stepper-step
                    step="1"
                    :complete="step1Complete"
                    @click="stepClicked(1)"
                  >
                    Search Type
                  </v-stepper-step>

                  <v-divider />

                  <v-stepper-step
                    step="2"
                    :complete="step2Complete"
                    @click="stepClicked(2)"
                  >
                    Geography
                  </v-stepper-step>

                  <v-divider />

                  <v-stepper-step
                    step="3"
                    :complete="step3Complete"
                    @click="stepClicked(3)"
                  >
                    Filters
                  </v-stepper-step>

                  <v-divider />

                  <v-stepper-step
                    step="4"
                    @click="stepClicked(4)"
                  >
                    Download
                  </v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                  <v-stepper-content step="1">
                    <div class="row align-center">
                      <div class="col-4 font-weight-bold text-right">
                        Select the Type of Search:
                      </div>

                      <div class="col-8">
                        <v-radio-group
                          v-model="sharedFormFields.search_type"
                          row
                        >
                          <v-radio
                            label="Consumer"
                            value="consumer"
                          />
                          <v-radio
                            label="Business"
                            value="business"
                          />
                        </v-radio-group>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-4 font-weight-bold text-right">
                        State:
                      </div>

                      <div class="col-8">
                        <v-select
                          v-model="sharedFormFields.state"
                          :items="states"
                          label="Please select a state"
                          dense
                          @change="loadCounties"
                        />
                      </div>
                    </div>

                    <div class="text-right">
                      <v-btn
                        color="primary"
                        :disabled="!step1Complete"
                        @click="step = 2"
                      >
                        Continue
                      </v-btn>
                    </div>
                  </v-stepper-content>

                  <v-stepper-content step="2">
                    <div class="mb-5 text-right">
                      <v-btn
                        color="primary"
                        @click="step = 3"
                      >
                        {{ step2Complete ? 'Continue' : 'Skip' }}
                      </v-btn>
                    </div>

                    <v-autocomplete
                      v-model="sharedFormFields.zips"
                      :loading="autocompleteData.zips.loading"
                      :items="autocompleteData.zips.items"
                      :error-messages="autocompleteData.zips.errors"
                      :search-input.sync="autocompleteSearchZips"
                      cache-items
                      hide-no-data
                      filled
                      chips
                      label="Zip Codes:"
                      multiple
                      maxlength="5"
                      hint="
                        Type the first 3 numbers of a zip code to display a
                        list of matching zip codes
                      "
                      persistent-hint
                    >
                      <template v-slot:selection="data">
                        <v-chip
                          v-bind="data.attrs"
                          :input-value="data.selected"
                          close
                          @click="data.select"
                          @click:close="removeZip(data.item)"
                        >
                          {{ data.item }}
                        </v-chip>
                      </template>
                      <template v-slot:item="data">
                        <v-list-item-content v-text="data.item" />
                      </template>
                    </v-autocomplete>

                    <v-divider class="mb-5" />

                    <v-autocomplete
                      v-model="sharedFormFields.towns"
                      :loading="autocompleteData.towns.loading"
                      :items="autocompleteData.towns.items"
                      :error-messages="autocompleteData.towns.errors"
                      :search-input.sync="autocompleteSearchTowns"
                      cache-items
                      hide-no-data
                      filled
                      chips
                      label="Towns / Cities:"
                      multiple
                      hint="
                        Type the first 3 letters of a city to display a list of
                        matching cities
                      "
                      persistent-hint
                    >
                      <template v-slot:selection="data">
                        <v-chip
                          v-bind="data.attrs"
                          :input-value="data.selected"
                          close
                          @click="data.select"
                          @click:close="removeTown(data.item)"
                        >
                          {{ data.item }}
                        </v-chip>
                      </template>
                      <template v-slot:item="data">
                        <v-list-item-content v-text="data.item" />
                      </template>
                    </v-autocomplete>

                    <v-divider
                      v-if="countiesStatus !== 'loaded' || countyChoices.length > 1"
                      class="mb-5"
                    />

                    <template v-if="countiesStatus === 'loading'">
                      Loading the list of counties

                      <v-progress-circular
                        indeterminate
                        :width="1"
                        :size="16"
                      />
                    </template>

                    <template v-else-if="countiesStatus === 'loaded' && countyChoices.length > 1">
                      <div class="mt-2 font-weight-bold">
                        Counties:
                      </div>

                      <div class="county-checkboxes">
                        <v-checkbox
                          v-for="(countyChoice, index) in countyChoices"
                          :key="`county${index}`"
                          v-model="sharedFormFields.counties"
                          :label="countyChoice"
                          :value="countyChoice"
                          hide-details
                        />
                      </div>
                    </template>

                    <template v-else-if="countiesStatus === 'error'">
                      <v-alert
                        dense
                        border="left"
                        type="error"
                      >
                        Unable to load the list of counties due to a
                        communication error. Please check your connection and
                        try again.
                      </v-alert>

                      <div class="text-right">
                        <v-btn @click="loadCounties">
                          Try Again
                        </v-btn>
                      </div>
                    </template>

                    <div class="mt-5 text-right">
                      <v-btn
                        color="primary"
                        @click="step = 3"
                      >
                        {{ step2Complete ? 'Continue' : 'Skip' }}
                      </v-btn>
                    </div>
                  </v-stepper-content>

                  <v-stepper-content step="3">
                    <div class="mb-5 text-right">
                      <v-btn
                        color="primary"
                        @click="step = 4"
                      >
                        {{ step3Complete ? 'Continue' : 'Skip' }}
                      </v-btn>
                    </div>

                    <template v-if="sharedFormFields.search_type === 'consumer'">
                      <div class="row">
                        <div class="col-4 mt-2 font-weight-bold text-right">
                          Gender:
                        </div>

                        <div class="col-8">
                          <v-row>
                            <v-col class="checkbox-cols">
                              <v-checkbox
                                v-for="(filterChoice, index) in filterChoices.consumer.gender"
                                :key="`filter_gender${index}`"
                                v-model="formFields.consumer.filter_gender"
                                :label="filterChoice.text"
                                :value="filterChoice.value"
                                hide-details
                              />
                            </v-col>
                          </v-row>
                        </div>
                      </div>

                      <v-divider class="my-5" />

                      <div class="row">
                        <div class="col-4 mt-2 font-weight-bold text-right">
                          Marital Status:
                        </div>

                        <div class="col-8">
                          <v-row>
                            <v-col class="checkbox-cols">
                              <v-checkbox
                                v-for="
                                  (filterChoice, index) in filterChoices.consumer.marital_status
                                "
                                :key="`filter_marital_status${index}`"
                                v-model="formFields.consumer.filter_marital_status"
                                :label="filterChoice.text"
                                :value="filterChoice.value"
                                hide-details
                              />
                            </v-col>
                          </v-row>
                        </div>
                      </div>

                      <v-divider class="my-5" />

                      <div class="row">
                        <div class="col-4 mt-2 font-weight-bold text-right">
                          Homeowner Status:
                        </div>

                        <div class="col-8">
                          <v-row>
                            <v-col class="checkbox-cols">
                              <v-checkbox
                                v-for="
                                  (filterChoice, index) in filterChoices.consumer.homeowner_status
                                "
                                :key="`filter_homeowner_status${index}`"
                                v-model="formFields.consumer.filter_homeowner_status"
                                :label="filterChoice.text"
                                :value="filterChoice.value"
                                hide-details
                              />
                            </v-col>
                          </v-row>
                        </div>
                      </div>

                      <v-divider class="my-5" />

                      <div class="row">
                        <div class="col-4 mt-2 font-weight-bold text-right">
                          Age Range:
                        </div>

                        <div class="col-8">
                          <v-row>
                            <v-col class="checkbox-cols">
                              <v-checkbox
                                v-for="(filterChoice, index) in filterChoices.consumer.age_range"
                                :key="`filter_age_range${index}`"
                                v-model="formFields.consumer.filter_age_range"
                                :label="filterChoice.text"
                                :value="filterChoice.value"
                                hide-details
                              />
                            </v-col>
                          </v-row>
                        </div>
                      </div>

                      <v-divider class="my-5" />

                      <div class="row">
                        <div class="col-4 mt-2 font-weight-bold text-right">
                          Year of Birth:
                        </div>

                        <div class="col-8">
                          <v-row>
                            <v-col>
                              <v-select
                                v-model="formFields.consumer.filter_year_of_birth_from"
                                :items="filterChoices.consumer.year_of_birth"
                                label="From"
                                filled
                                clearable
                              />

                              <v-select
                                v-model="formFields.consumer.filter_year_of_birth_to"
                                :items="filterChoices.consumer.year_of_birth"
                                label="To"
                                filled
                                clearable
                              />
                            </v-col>
                          </v-row>
                        </div>
                      </div>

                      <v-divider class="my-5" />

                      <div class="row">
                        <div class="col-4 mt-2 font-weight-bold text-right">
                          Month of Birth:
                        </div>

                        <div class="col-8">
                          <v-row>
                            <v-col class="checkbox-cols">
                              <v-checkbox
                                v-for="
                                  (filterChoice, index) in filterChoices.consumer.month_of_birth
                                "
                                :key="`filter_month_of_birth${index}`"
                                v-model="formFields.consumer.filter_month_of_birth"
                                :label="filterChoice.text"
                                :value="filterChoice.value"
                                hide-details
                              />
                            </v-col>
                          </v-row>
                        </div>
                      </div>

                      <v-divider class="my-5" />

                      <div class="row">
                        <div class="col-4 mt-2 font-weight-bold text-right">
                          Day of Birth:
                        </div>

                        <div class="col-8">
                          <v-row>
                            <v-col class="checkbox-cols checkbox-cols4">
                              <v-checkbox
                                v-for="(filterChoice, index) in filterChoices.consumer.day_of_birth"
                                :key="`filter_day_of_birth${index}`"
                                v-model="formFields.consumer.filter_day_of_birth"
                                :label="filterChoice.toString()"
                                :value="filterChoice"
                                hide-details
                              />
                            </v-col>
                          </v-row>
                        </div>
                      </div>

                      <v-divider class="my-5" />

                      <div class="row">
                        <div class="col-4 mt-2 font-weight-bold text-right">
                          Net Worth:
                        </div>

                        <div class="col-8">
                          <v-row>
                            <v-col class="checkbox-cols">
                              <v-checkbox
                                v-for="(filterChoice, index) in filterChoices.consumer.net_worth"
                                :key="`filter_net_worth${index}`"
                                v-model="formFields.consumer.filter_net_worth"
                                :label="filterChoice.text"
                                :value="filterChoice.value"
                                hide-details
                              />
                            </v-col>
                          </v-row>
                        </div>
                      </div>

                      <v-divider class="my-5" />

                      <div class="row">
                        <div class="col-4 mt-2 font-weight-bold text-right">
                          Income:
                        </div>

                        <div class="col-8">
                          <v-row>
                            <v-col class="checkbox-cols">
                              <v-checkbox
                                v-for="(filterChoice, index) in filterChoices.consumer.income"
                                :key="`filter_income${index}`"
                                v-model="formFields.consumer.filter_income"
                                :label="filterChoice.text"
                                :value="filterChoice.value"
                                hide-details
                              />
                            </v-col>
                          </v-row>
                        </div>
                      </div>

                      <v-divider class="my-5" />

                      <div class="row">
                        <div class="col-4 mt-2 font-weight-bold text-right">
                          Credit Rating:
                        </div>

                        <div class="col-8">
                          <v-row>
                            <v-col class="checkbox-cols">
                              <v-checkbox
                                v-for="
                                  (filterChoice, index) in filterChoices.consumer.credit_rating
                                "
                                :key="`filter_credit_rating${index}`"
                                v-model="formFields.consumer.filter_credit_rating"
                                :label="filterChoice.text"
                                :value="filterChoice.value"
                                hide-details
                              />
                            </v-col>
                          </v-row>
                        </div>
                      </div>

                      <v-divider class="my-5" />

                      <div class="row">
                        <div class="col-4 mt-2 font-weight-bold text-right">
                          Dwell Type:
                        </div>

                        <div class="col-8">
                          <v-row>
                            <v-col>
                              <v-checkbox
                                v-for="(filterChoice, index) in filterChoices.consumer.dwell_type"
                                :key="`filter_dwell_type${index}`"
                                v-model="formFields.consumer.filter_dwell_type"
                                :label="filterChoice.text"
                                :value="filterChoice.value"
                                hide-details
                              />
                            </v-col>
                          </v-row>
                        </div>
                      </div>

                      <v-divider class="my-5" />

                      <div class="row">
                        <div class="col-4 mt-2 font-weight-bold text-right">
                          Have Phone Number:
                        </div>

                        <div class="col-8">
                          <v-row>
                            <v-col class="checkbox-cols">
                              <v-checkbox
                                v-for="
                                  (filterChoice, index) in filterChoices.consumer.phone_number
                                "
                                :key="`filter_consumer_phone_number${index}`"
                                v-model="formFields.consumer.filter_phone_number"
                                :label="filterChoice.text"
                                :value="filterChoice.value"
                                hide-details
                              />
                            </v-col>
                          </v-row>
                        </div>
                      </div>

                      <v-divider class="my-5" />

                      <div class="row">
                        <div class="col-4 mt-2 font-weight-bold text-right">
                          Have Email Address:
                        </div>

                        <div class="col-8">
                          <v-row>
                            <v-col class="checkbox-cols">
                              <v-checkbox
                                v-for="
                                  (filterChoice, index) in filterChoices.consumer.email_address
                                "
                                :key="`filter_consumer_email_address${index}`"
                                v-model="formFields.consumer.filter_email_address"
                                :label="filterChoice.text"
                                :value="filterChoice.value"
                                hide-details
                              />
                            </v-col>
                          </v-row>
                        </div>
                      </div>

                      <v-divider class="my-5" />

                      <div class="row">
                        <div class="col-4 mt-2 font-weight-bold text-right">
                          Ethnic Group:
                        </div>

                        <div class="col-8">
                          <v-row>
                            <v-col class="checkbox-cols">
                              <v-checkbox
                                v-for="(filterChoice, index) in filterChoices.consumer.ethnic_group"
                                :key="`filter_ethnic_group${index}`"
                                v-model="formFields.consumer.filter_ethnic_group"
                                :label="filterChoice.text"
                                :value="filterChoice.value"
                                hide-details
                              />
                            </v-col>
                          </v-row>
                        </div>
                      </div>

                      <v-divider class="my-5" />

                      <div class="row">
                        <div class="col-4 mt-2 font-weight-bold text-right">
                          Religion:
                        </div>

                        <div class="col-8">
                          <v-row>
                            <v-col class="checkbox-cols">
                              <v-checkbox
                                v-for="(filterChoice, index) in filterChoices.consumer.religion"
                                :key="`filter_religion${index}`"
                                v-model="formFields.consumer.filter_religion"
                                :label="filterChoice.text"
                                :value="filterChoice.value"
                                hide-details
                              />
                            </v-col>
                          </v-row>
                        </div>
                      </div>

                      <v-divider class="my-5" />

                      <div class="row">
                        <div class="col-4 mt-2 font-weight-bold text-right">
                          Presence of Children:
                        </div>

                        <div class="col-8">
                          <v-row>
                            <v-col class="checkbox-cols">
                              <v-checkbox
                                v-for="(filterChoice, index) in filterChoices.consumer.children"
                                :key="`filter_children${index}`"
                                v-model="formFields.consumer.filter_children"
                                :label="filterChoice.text"
                                :value="filterChoice.value"
                                hide-details
                              />
                            </v-col>
                          </v-row>
                        </div>
                      </div>
                    </template>

                    <template v-else-if="sharedFormFields.search_type === 'business'">
                      <div class="row">
                        <div class="col-4 mt-2 font-weight-bold text-right">
                          SIC Primary Code:
                        </div>

                        <div class="col-8">
                          <v-row>
                            <v-col>
                              <v-autocomplete
                                v-model="formFields.business.filter_sic_code"
                                :loading="autocompleteData.sic_codes.loading"
                                :items="autocompleteData.sic_codes.items"
                                :error-messages="autocompleteData.sic_codes.errors"
                                :search-input.sync="autocompleteSearchSicCodes"
                                cache-items
                                hide-no-data
                                filled
                                chips
                                multiple
                                maxlength="8"
                                hint="
                                  Type the first 3 numbers of a SIC Code OR any 3
                                  characters within the SIC Code description, to
                                  display a list of matching SIC Codes
                                "
                                persistent-hint
                              >
                                <template v-slot:selection="data">
                                  <v-chip
                                    v-bind="data.attrs"
                                    :input-value="data.selected"
                                    close
                                    @click="data.select"
                                    @click:close="removeSicCode(data.item)"
                                  >
                                    {{ data.item }}
                                  </v-chip>
                                </template>
                                <template v-slot:item="data">
                                  <v-list-item-content v-text="data.item" />
                                </template>
                              </v-autocomplete>
                            </v-col>
                          </v-row>
                        </div>
                      </div>

                      <v-divider class="my-5" />

                      <div class="row">
                        <div class="col-4 mt-2 font-weight-bold text-right">
                          Sales Volume:
                        </div>

                        <div class="col-8">
                          <v-row>
                            <v-col class="checkbox-cols">
                              <v-checkbox
                                v-for="(filterChoice, index) in filterChoices.business.sales"
                                :key="`filter_sales${index}`"
                                v-model="formFields.business.filter_sales"
                                :label="filterChoice.text"
                                :value="filterChoice.value"
                                hide-details
                              />
                            </v-col>
                          </v-row>
                        </div>
                      </div>

                      <v-divider class="my-5" />

                      <div class="row">
                        <div class="col-4 mt-2 font-weight-bold text-right">
                          Number of Employees:
                        </div>

                        <div class="col-8">
                          <v-row>
                            <v-col class="checkbox-cols">
                              <v-checkbox
                                v-for="(filterChoice, index) in filterChoices.business.employees"
                                :key="`filter_employees${index}`"
                                v-model="formFields.business.filter_employees"
                                :label="filterChoice.text"
                                :value="filterChoice.value"
                                hide-details
                              />
                            </v-col>
                          </v-row>
                        </div>
                      </div>

                      <v-divider class="my-5" />

                      <div class="row">
                        <div class="col-4 mt-2 font-weight-bold text-right">
                          Email Exists:
                        </div>

                        <div class="col-8">
                          <v-row>
                            <v-col class="checkbox-cols">
                              <v-checkbox
                                v-for="
                                  (filterChoice, index) in filterChoices.business.email_address
                                "
                                :key="`filter_business_email_address${index}`"
                                v-model="formFields.business.filter_email_address"
                                :label="filterChoice.text"
                                :value="filterChoice.value"
                                hide-details
                              />
                            </v-col>
                          </v-row>
                        </div>
                      </div>
                    </template>

                    <div class="mt-5 text-right">
                      <v-btn
                        color="primary"
                        @click="step = 4"
                      >
                        {{ step3Complete ? 'Continue' : 'Skip' }}
                      </v-btn>
                    </div>
                  </v-stepper-content>

                  <v-stepper-content step="4">
                    <div class="download-button-and-wait-time-message mb-5">
                      <v-btn
                        color="primary"
                        @click="download"
                      >
                        download
                      </v-btn>
                      <div class="download-wait-time-message text-caption">
                        This process could take up to 2 minutes, depending on
                        the number of records you have requested.
                      </div>
                    </div>

                    <div class="row align-center">
                      <div class="col-4 font-weight-bold text-right">
                        File Description:
                      </div>

                      <div class="col-8">
                        <v-text-field
                          v-model="sharedFormFields.file_description"
                        />
                      </div>
                    </div>

                    <div class="row align-center">
                      <div class="col-4 font-weight-bold text-right">
                        How Many Records:
                      </div>

                      <div class="col-8">
                        <v-text-field
                          v-model="sharedFormFields.records"
                          :hint="maxRecords ? `(maximum: ${formatNumber(maxRecords)})` : ''"
                          persistent-hint
                          :error-messages="formErrors.records"
                        />
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-4 mt-2 font-weight-bold text-right">
                        Select Fields:
                      </div>

                      <div class="col-8">
                        <v-row>
                          <v-col>
                            <v-checkbox
                              v-for="(field, index) in fields[sharedFormFields.search_type]"
                              :key="`field${index}`"
                              v-model="formFields[sharedFormFields.search_type].fields"
                              :label="field.text"
                              :value="field.value"
                              :error-messages="formErrors.fields"
                              :hide-details="
                                index !== fields[sharedFormFields.search_type].length - 1
                              "
                            />
                          </v-col>
                        </v-row>
                      </div>
                    </div>

                    <div class="download-button-and-wait-time-message">
                      <v-btn
                        color="primary"
                        @click="download"
                      >
                        download
                      </v-btn>
                      <div class="download-wait-time-message text-caption">
                        This process could take up to 2 minutes, depending on
                        the number of records you have requested.
                      </div>
                    </div>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>

              <v-btn
                v-if="goToTopButtonShowing"
                aria-label="Go to Top"
                class="go-to-top-button"
                @click="goToTopButtonClicked"
              >
                <v-icon>mdi-arrow-collapse-up</v-icon>
              </v-btn>

              <v-overlay :value="downloading">
                <v-progress-circular
                  indeterminate
                  size="64"
                />
              </v-overlay>
            </template>

            <v-progress-circular
              v-else-if="savedSearchStatus === 'loading'"
              indeterminate
              :size="64"
              class="d-block mx-auto"
            />

            <template v-else-if="savedSearchStatus === 'load_error'">
              <v-alert
                dense
                border="left"
                type="error"
              >
                Unable to load your saved search due to a communication error.
                Please check your connection and try again.
              </v-alert>

              <div class="text-right">
                <v-btn @click="loadSavedSearch">
                  Try Again
                </v-btn>
              </div>
            </template>

            <v-alert
              v-else-if="savedSearchStatus === 'not_found'"
              dense
              border="left"
              type="error"
            >
              That saved search wasn't found.
            </v-alert>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog
      v-model="savedSearchDialogShowing"
      persistent
      scrollable
      max-width="600"
    >
      <v-card>
        <v-card-title class="text-h5">
          <template v-if="savedSearchId === null">
            Save This Search
          </template>

          <template v-else>
            Saved Search
          </template>
        </v-card-title>

        <v-divider />

        <v-card-text>
          <v-alert
            v-if="savedSearchFormErrors.non_field_errors"
            dense
            border="left"
            type="warning"
          >
            {{ savedSearchFormErrors.non_field_errors[0] }}
          </v-alert>

          <form
            id="saved-search-form"
            @submit.prevent="submitSavedSearchForm"
          >
            <v-text-field
              v-model="savedSearchFormFields.search_name"
              label="Name this search"
              :error-messages="savedSearchFormErrors.search_name"
              required
            />
          </form>

          <table
            v-if="step1Complete"
            class="search-criteria-table"
          >
            <tr>
              <th>Search Type</th>
              <td>{{ capitalizeFirstLetter(sharedFormFields.search_type) }}</td>
            </tr>
            <tr>
              <th>State</th>
              <td>{{ sharedFormFields.state }}</td>
            </tr>
            <tr v-if="sharedFormFields.zips.length">
              <th>Zip Codes</th>
              <td>{{ toListSentence(sharedFormFields.zips) }}</td>
            </tr>
            <tr v-if="sharedFormFields.towns.length">
              <th>Towns / Cities</th>
              <td>{{ toListSentence(sharedFormFields.towns) }}</td>
            </tr>
            <tr v-if="sharedFormFields.counties.length">
              <th>Counties</th>
              <td>{{ toListSentence(sharedFormFields.counties) }}</td>
            </tr>

            <template v-if="sharedFormFields.search_type === 'consumer'">
              <tr v-if="formFields.consumer.filter_gender.length">
                <th>Gender</th>
                <td>
                  {{
                    toListSentence(
                      getFilterChoicesFromMap(
                        formFields.consumer.filter_gender,
                        'consumer',
                        'gender',
                      )
                    )
                  }}
                </td>
              </tr>
              <tr v-if="formFields.consumer.filter_marital_status.length">
                <th>Marital Status</th>
                <td>
                  {{
                    toListSentence(
                      getFilterChoicesFromMap(
                        formFields.consumer.filter_marital_status,
                        'consumer',
                        'marital_status',
                      )
                    )
                  }}
                </td>
              </tr>
              <tr v-if="formFields.consumer.filter_homeowner_status.length">
                <th>Homeowner Status</th>
                <td>
                  {{
                    toListSentence(
                      getFilterChoicesFromMap(
                        formFields.consumer.filter_homeowner_status,
                        'consumer',
                        'homeowner_status',
                      )
                    )
                  }}
                </td>
              </tr>
              <tr v-if="formFields.consumer.filter_age_range.length">
                <th>Age Range</th>
                <td>
                  {{
                    toListSentence(
                      getFilterChoicesFromMap(
                        formFields.consumer.filter_age_range,
                        'consumer',
                        'age_range',
                      )
                    )
                  }}
                </td>
              </tr>
              <tr
                v-if="
                  formFields.consumer.filter_year_of_birth_from !== null
                    || formFields.consumer.filter_year_of_birth_to !== null
                "
              >
                <th>Year of Birth</th>
                <td>
                  <template v-if="formFields.consumer.filter_year_of_birth_from !== null">
                    {{ formFields.consumer.filter_year_of_birth_from }}

                    <template v-if="formFields.consumer.filter_year_of_birth_to !== null">
                      to
                    </template>
                  </template>

                  <template v-if="formFields.consumer.filter_year_of_birth_to !== null">
                    {{ formFields.consumer.filter_year_of_birth_to }}
                  </template>
                </td>
              </tr>
              <tr v-if="formFields.consumer.filter_month_of_birth.length">
                <th>Month of Birth</th>
                <td>
                  {{
                    toListSentence(
                      getFilterChoicesFromMap(
                        formFields.consumer.filter_month_of_birth,
                        'consumer',
                        'month_of_birth',
                      )
                    )
                  }}
                </td>
              </tr>
              <tr v-if="formFields.consumer.filter_day_of_birth.length">
                <th>Day of Birth</th>
                <td>{{ toListSentence(formFields.consumer.filter_day_of_birth) }}</td>
              </tr>
              <tr v-if="formFields.consumer.filter_net_worth.length">
                <th>Net Worth</th>
                <td>
                  {{
                    toListSentence(
                      getFilterChoicesFromMap(
                        formFields.consumer.filter_net_worth,
                        'consumer',
                        'net_worth',
                      )
                    )
                  }}
                </td>
              </tr>
              <tr v-if="formFields.consumer.filter_income.length">
                <th>Income</th>
                <td>
                  {{
                    toListSentence(
                      getFilterChoicesFromMap(
                        formFields.consumer.filter_income,
                        'consumer',
                        'income',
                      )
                    )
                  }}
                </td>
              </tr>
              <tr v-if="formFields.consumer.filter_credit_rating.length">
                <th>Credit Rating</th>
                <td>
                  {{
                    toListSentence(
                      getFilterChoicesFromMap(
                        formFields.consumer.filter_credit_rating,
                        'consumer',
                        'credit_rating',
                      )
                    )
                  }}
                </td>
              </tr>
              <tr v-if="formFields.consumer.filter_dwell_type.length">
                <th>Dwell Type</th>
                <td>
                  {{
                    toListSentence(
                      getFilterChoicesFromMap(
                        formFields.consumer.filter_dwell_type,
                        'consumer',
                        'dwell_type',
                      )
                    )
                  }}
                </td>
              </tr>
              <tr
                v-if="
                  formFields.consumer.filter_phone_number !== undefined
                    && formFields.consumer.filter_phone_number.length
                "
              >
                <th>Have Phone Number</th>
                <td>
                  {{
                    toListSentence(
                      getFilterChoicesFromMap(
                        formFields.consumer.filter_phone_number,
                        'consumer',
                        'phone_number',
                      )
                    )
                  }}
                </td>
              </tr>
              <tr v-if="formFields.consumer.filter_email_address.length">
                <th>Have Email Address</th>
                <td>
                  {{
                    toListSentence(
                      getFilterChoicesFromMap(
                        formFields.consumer.filter_email_address,
                        'consumer',
                        'email_address',
                      )
                    )
                  }}
                </td>
              </tr>
              <tr v-if="formFields.consumer.filter_ethnic_group.length">
                <th>Ethnic Group</th>
                <td>
                  {{
                    toListSentence(
                      getFilterChoicesFromMap(
                        formFields.consumer.filter_ethnic_group,
                        'consumer',
                        'ethnic_group',
                      )
                    )
                  }}
                </td>
              </tr>
              <tr v-if="formFields.consumer.filter_religion.length">
                <th>Religion</th>
                <td>
                  {{
                    toListSentence(
                      getFilterChoicesFromMap(
                        formFields.consumer.filter_religion,
                        'consumer',
                        'religion',
                      )
                    )
                  }}
                </td>
              </tr>
              <tr v-if="formFields.consumer.filter_children.length">
                <th>Presence of Children</th>
                <td>
                  {{
                    toListSentence(
                      getFilterChoicesFromMap(
                        formFields.consumer.filter_children,
                        'consumer',
                        'children',
                      )
                    )
                  }}
                </td>
              </tr>
            </template>

            <template v-else>
              <tr v-if="formFields.business.filter_sic_code.length">
                <th>SIC Primary Code</th>
                <td>{{ toListSentence(formFields.business.filter_sic_code) }}</td>
              </tr>
              <tr v-if="formFields.business.filter_sales.length">
                <th>Sales Volume</th>
                <td>
                  {{
                    toListSentence(
                      getFilterChoicesFromMap(
                        formFields.business.filter_sales,
                        'business',
                        'sales',
                      )
                    )
                  }}
                </td>
              </tr>
              <tr v-if="formFields.business.filter_employees.length">
                <th>Number of Employees</th>
                <td>
                  {{
                    toListSentence(
                      getFilterChoicesFromMap(
                        formFields.business.filter_employees,
                        'business',
                        'employees',
                      )
                    )
                  }}
                </td>
              </tr>
              <tr v-if="formFields.business.filter_email_address.length">
                <th>Email Exists</th>
                <td>
                  {{
                    toListSentence(
                      getFilterChoicesFromMap(
                        formFields.business.filter_email_address,
                        'business',
                        'email_address',
                      )
                    )
                  }}
                </td>
              </tr>
            </template>
          </table>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />

          <v-btn
            text
            :disabled="savedSearchFormSubmitting"
            @click="savedSearchDialogShowing = false"
          >
            Cancel
          </v-btn>

          <v-btn
            type="submit"
            form="saved-search-form"
            color="primary"
            :disabled="savedSearchFormSubmitting"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="savedSearchSnackbarData.showing"
      :timeout="2000"
      color="success"
    >
      <div class="text-center">
        {{ savedSearchSnackbarData.text }}
      </div>
    </v-snackbar>
  </div>
</template>

<script>
import getFilterChoices, { getFilterChoicesMap } from '@/methods/get_filter_choices';

export default {
  name: 'SearchView',
  props: {
    savedSearchId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    const fields = {
      consumer: [
        { value: 'name_prefix', text: 'Name Prefix' },
        { value: 'first_name', text: 'First Name' },
        { value: 'middle_innitial', text: 'Middle Initial' },
        { value: 'last_name', text: 'Last Name' },
        { value: 'phone_number', text: 'Phone' },
        { value: 'phone_number1', text: 'Phone2' },
        { value: 'email', text: 'Email ID' },
        { value: 'address_line', text: 'Address' },
        { value: 'city', text: 'City' },
        { value: 'state', text: 'State' },
        { value: 'zip5', text: 'Zip' },
        { value: 'county', text: 'County Name' },
        { value: 'birth_year', text: 'Birth Year' },
        { value: 'birth_month', text: 'Birth Month' },
        { value: 'birth_day', text: 'Birth Day' },
        { value: 'age', text: 'Age' },
        { value: 'marital_status', text: 'Marital Status' },
        { value: 'home_owner', text: 'Homeowner Status' },
        { value: 'hh_income', text: 'Estimated Income' },
        { value: 'net_worth', text: 'Net Worth' },
        { value: 'credit_rating', text: 'Credit Rating' },
        { value: 'dwell_type', text: 'Dwell Type' },
        { value: 'ethnic_group', text: 'Ethnic Group' },
        { value: 'religion', text: 'Religion' },
        { value: 'presence_of_children', text: 'Presence of Children' },
        { value: 'gender', text: 'Gender' },
      ],
      business: [
        { value: 'company_name', text: 'Business Name' },
        { value: 'phone', text: 'Phone' },
        { value: 'email', text: 'Email ID' },
        { value: 'fax', text: 'Fax' },
        { value: 'contact_title', text: 'Title' },
        { value: 'contact', text: 'Contact Name' },
        { value: 'address_line', text: 'Address' },
        { value: 'city', text: 'City' },
        { value: 'state', text: 'State' },
        { value: 'zipcode', text: 'Zip' },
        { value: 'county', text: 'County Name' },
        { value: 'web_address', text: 'Web Address' },
        { value: 'sales_volume_txt', text: 'SalesVolume' },
        { value: 'sic_code', text: 'SIC Code' },
        { value: 'sic_description', text: 'SIC Description' },
        { value: 'employees_txt', text: 'No. of Employees' },
      ],
    };

    return {
      scrollObserver: null,
      goToTopButtonShowing: false,
      gettingLeadCount: false,
      leadCount: null,
      leadCountConditions: null,
      downloading: false,
      step: 1,
      formErrors: {},
      sharedFormFields: {
        search_type: null,
        state: null,
        zips: [],
        towns: [],
        counties: [],
        file_description: '',
        records: '1000',
      },
      formFields: {
        consumer: {
          filter_gender: [],
          filter_marital_status: [],
          filter_homeowner_status: [],
          filter_age_range: [],
          filter_year_of_birth_from: null,
          filter_year_of_birth_to: null,
          filter_month_of_birth: [],
          filter_day_of_birth: [],
          filter_net_worth: [],
          filter_income: [],
          filter_credit_rating: [],
          filter_dwell_type: [],
          filter_phone_number: [],
          filter_email_address: [],
          filter_ethnic_group: [],
          filter_religion: [],
          filter_children: [],
          fields: fields.consumer.map((f) => f.value),
        },
        business: {
          filter_sic_code: [],
          filter_sales: [],
          filter_employees: [],
          filter_email_address: [],
          fields: fields.business.map((f) => f.value),
        },
      },
      autocompleteData: {
        zips: {
          loading: false,
          items: [],
          recentSearches: [],
          errors: [],
        },
        towns: {
          loading: false,
          items: [],
          recentSearches: [],
          errors: [],
        },
        sic_codes: {
          loading: false,
          items: [],
          recentSearches: [],
          errors: [],
        },
      },
      autocompleteSearchZips: null,
      autocompleteSearchTowns: null,
      autocompleteSearchSicCodes: null,
      states: [
        { value: 'AL', text: 'Alabama' },
        { value: 'AK', text: 'Alaska' },
        { value: 'AZ', text: 'Arizona' },
        { value: 'AR', text: 'Arkansas' },
        { value: 'CA', text: 'California' },
        { value: 'CO', text: 'Colorado' },
        { value: 'CT', text: 'Connecticut' },
        { value: 'DE', text: 'Delaware' },
        { value: 'DC', text: 'District of Columbia' },
        { value: 'FL', text: 'Florida' },
        { value: 'GA', text: 'Georgia' },
        { value: 'HI', text: 'Hawaii' },
        { value: 'ID', text: 'Idaho' },
        { value: 'IL', text: 'Illinois' },
        { value: 'IN', text: 'Indiana' },
        { value: 'IA', text: 'Iowa' },
        { value: 'KS', text: 'Kansas' },
        { value: 'KY', text: 'Kentucky' },
        { value: 'LA', text: 'Louisiana' },
        { value: 'ME', text: 'Maine' },
        { value: 'MD', text: 'Maryland' },
        { value: 'MA', text: 'Massachusetts' },
        { value: 'MI', text: 'Michigan' },
        { value: 'MN', text: 'Minnesota' },
        { value: 'MS', text: 'Mississippi' },
        { value: 'MO', text: 'Missouri' },
        { value: 'MT', text: 'Montana' },
        { value: 'NE', text: 'Nebraska' },
        { value: 'NV', text: 'Nevada' },
        { value: 'NH', text: 'New Hampshire' },
        { value: 'NJ', text: 'New Jersey' },
        { value: 'NM', text: 'New Mexico' },
        { value: 'NY', text: 'New York' },
        { value: 'NC', text: 'North Carolina' },
        { value: 'ND', text: 'North Dakota' },
        { value: 'OH', text: 'Ohio' },
        { value: 'OK', text: 'Oklahoma' },
        { value: 'OR', text: 'Oregon' },
        { value: 'PA', text: 'Pennsylvania' },
        { value: 'RI', text: 'Rhode Island' },
        { value: 'SC', text: 'South Carolina' },
        { value: 'SD', text: 'South Dakota' },
        { value: 'TN', text: 'Tennessee' },
        { value: 'TX', text: 'Texas' },
        { value: 'UT', text: 'Utah' },
        { value: 'VT', text: 'Vermont' },
        { value: 'VA', text: 'Virginia' },
        { value: 'WA', text: 'Washington' },
        { value: 'WV', text: 'West Virginia' },
        { value: 'WI', text: 'Wisconsin' },
        { value: 'WY', text: 'Wyoming' },
      ],
      countiesStatus: 'idle',
      countyChoicesInitialLoadComplete: false,
      countyChoices: [],
      filterChoices: getFilterChoices(),
      filterChoicesMap: getFilterChoicesMap(),
      fields,
      savedSearchDialogShowing: false,
      savedSearchStatus: 'idle',
      savedSearchData: {},
      savedSearchFormFields: {
        search_name: '',
      },
      savedSearchFormErrors: {},
      savedSearchFormSubmitting: false,
      savedSearchSnackbarData: {
        showing: false,
        text: '',
      },
      maxRecords: 0,
    };
  },
  computed: {
    step1Complete() {
      return this.sharedFormFields.search_type !== null && this.sharedFormFields.state !== null;
    },
    step2Complete() {
      return Boolean(this.step >= 3 || (
        this.sharedFormFields.zips.length
        || this.sharedFormFields.towns.length
        || this.sharedFormFields.counties.length
      ));
    },
    step3Complete() {
      return Boolean(this.step >= 4 || (
        this.sharedFormFields.search_type === 'consumer' && (
          this.formFields.consumer.filter_gender.length
          || this.formFields.consumer.filter_marital_status.length
          || this.formFields.consumer.filter_homeowner_status.length
          || this.formFields.consumer.filter_age_range.length
          || this.formFields.consumer.filter_year_of_birth_from !== null
          || this.formFields.consumer.filter_year_of_birth_to !== null
          || this.formFields.consumer.filter_month_of_birth.length
          || this.formFields.consumer.filter_day_of_birth.length
          || this.formFields.consumer.filter_net_worth.length
          || this.formFields.consumer.filter_income.length
          || this.formFields.consumer.filter_credit_rating.length
          || this.formFields.consumer.filter_dwell_type.length
          || this.formFields.consumer.filter_phone_number.length
          || this.formFields.consumer.filter_email_address.length
          || this.formFields.consumer.filter_ethnic_group.length
          || this.formFields.consumer.filter_religion.length
          || this.formFields.consumer.filter_children.length
        )
      ) || (
        this.sharedFormFields.search_type === 'business' && (
          this.formFields.business.filter_sic_code.length
          || this.formFields.business.filter_sales.length
          || this.formFields.business.filter_employees.length
          || this.formFields.business.filter_email_address.length
        )
      ));
    },
  },
  metaInfo() {
    let title;

    if (this.savedSearchId === null) {
      title = 'New Search';
    } else {
      title = 'Saved Search';

      if (this.savedSearchStatus === 'loaded') {
        title += ` - ${this.savedSearchData.search_name}`;
      }
    }

    return {
      title,
    };
  },
  watch: {
    step() {
      window.scrollTo(0, 0);
    },
    step1Complete() {
      this.getLeadCount();
    },
    autocompleteSearchZips(val) {
      this.autocompleteData.zips.errors = [];

      if (
        val !== null
        && val.length >= 3
        && !this.autocompleteData.zips.recentSearches.includes(val)
      ) {
        this.getAutocomplete('zips', val);
      }
    },
    autocompleteSearchTowns(val) {
      this.autocompleteData.towns.errors = [];

      if (
        val !== null
        && val.length >= 3
        && !this.autocompleteData.towns.recentSearches.includes(val)
      ) {
        this.getAutocomplete('towns', val);
      }
    },
    autocompleteSearchSicCodes(val) {
      this.autocompleteData.sic_codes.errors = [];

      if (
        val !== null
        && val.length >= 3
        && !this.autocompleteData.sic_codes.recentSearches.includes(val)
      ) {
        this.getAutocomplete('sic_codes', val);
      }
    },
    formErrors() {
      if (!this.isObjectEmpty(this.formErrors)) {
        this.$nextTick(this.scrollToFirstError);
      }
    },
  },
  async created() {
    if (this.savedSearchId === 0) {
      this.savedSearchStatus = 'not_found';
    } else if (this.savedSearchId !== null) {
      this.loadSavedSearch();
    }

    // Make a request to get the user's maximum records per download. If
    // the request fails, we just won't show the number.

    const responseData = await this.api({
      url: 'users/get_max_records_per_download/',
    });

    if (responseData.status === 200) {
      this.maxRecords = responseData.body.max_records_per_download;
    }
  },
  mounted() {
    this.scrollObserver = new IntersectionObserver(this.setGoToTopButtonShowing);
    this.scrollObserver.observe(document.getElementById('before-main'));
  },
  beforeRouteUpdate(to, from, next) {
    if (!this.savedSearchDialogShowing) {
      this.$store.commit('routerViewKeySuffixIncrement');
      window.scrollTo(0, 0);
    }

    next();
  },
  beforeDestroy() {
    if (this.scrollObserver) {
      this.scrollObserver.unobserve(document.getElementById('before-main'));
    }
  },
  methods: {
    async newSearch() {
      let message = 'Are you sure you want to start ';

      if (this.savedSearchId === null) {
        message += 'over?';
      } else {
        message += 'a new search?';
      }

      // eslint-disable-next-line no-alert
      if (window.confirm(message)) {
        if (this.savedSearchId !== null) {
          await this.$router.push({ name: 'search' });
        }

        this.$store.commit('routerViewKeySuffixIncrement');
        window.scrollTo(0, 0);
      }
    },
    setGoToTopButtonShowing(entries) {
      this.goToTopButtonShowing = !entries[0].isIntersecting;
    },
    goToTopButtonClicked() {
      window.scrollTo(0, 0);
    },
    async loadSavedSearch() {
      this.savedSearchStatus = 'loading';

      const responseData = await this.api({
        url: `saved_searches/${this.savedSearchId}/`,
      });

      if (responseData.status === 200) {
        this.savedSearchData = responseData.body;

        this.savedSearchFormFields.search_name = this.savedSearchData.search_name;

        this.sharedFormFields.zips = this.savedSearchData.parameters.zips;
        this.autocompleteData.zips.items = this.savedSearchData.parameters.zips;
        this.sharedFormFields.towns = this.savedSearchData.parameters.towns;
        this.autocompleteData.towns.items = this.savedSearchData.parameters.towns;
        this.sharedFormFields.counties = this.savedSearchData.parameters.counties;

        if (this.savedSearchData.search_type === 'consumer') {
          this.formFields.consumer.filter_gender = this.savedSearchData.parameters.filters.gender;
          this.formFields.consumer.filter_marital_status = this.savedSearchData.parameters.filters
            .marital_status;
          this.formFields.consumer.filter_homeowner_status = this.savedSearchData.parameters.filters
            .homeowner_status;
          this.formFields.consumer.filter_age_range = this.savedSearchData.parameters.filters
            .age_range;
          this.formFields.consumer.filter_year_of_birth_from = this.savedSearchData.parameters
            .filters.year_of_birth.from;
          this.formFields.consumer.filter_year_of_birth_to = this.savedSearchData.parameters.filters
            .year_of_birth.to;
          this.formFields.consumer.filter_month_of_birth = this.savedSearchData.parameters.filters
            .month_of_birth;
          this.formFields.consumer.filter_day_of_birth = this.savedSearchData.parameters.filters
            .day_of_birth;
          this.formFields.consumer.filter_net_worth = this.savedSearchData.parameters.filters
            .net_worth;
          this.formFields.consumer.filter_income = this.savedSearchData.parameters.filters.income;
          this.formFields.consumer.filter_credit_rating = this.savedSearchData.parameters.filters
            .credit_rating;
          this.formFields.consumer.filter_dwell_type = this.savedSearchData.parameters.filters
            .dwell_type;
          this.formFields.consumer.filter_phone_number = this.savedSearchData.parameters.filters
            .phone_number;
          this.formFields.consumer.filter_email_address = this.savedSearchData.parameters.filters
            .email_address;
          this.formFields.consumer.filter_ethnic_group = this.savedSearchData.parameters.filters
            .ethnic_group;
          this.formFields.consumer.filter_religion = this.savedSearchData.parameters.filters
            .religion;
          this.formFields.consumer.filter_children = this.savedSearchData.parameters.filters
            .children;
        } else {
          this.formFields.business.filter_sic_code = this.savedSearchData.parameters.filters
            .sic_code;
          this.autocompleteData.sic_codes.items = this.savedSearchData.parameters.filters.sic_code;
          this.formFields.business.filter_sales = this.savedSearchData.parameters.filters.sales;
          this.formFields.business.filter_employees = this.savedSearchData.parameters.filters
            .employees;
          this.formFields.business.filter_email_address = this.savedSearchData.parameters.filters
            .email_address;
        }

        this.sharedFormFields.search_type = this.savedSearchData.search_type;
        this.sharedFormFields.state = this.savedSearchData.state;

        this.$set(this.savedSearchData, 'original_json', JSON.stringify(this.getSearchJson()));

        this.loadCounties();
        this.savedSearchStatus = 'loaded';
      } else if (responseData.status === 404) {
        this.savedSearchStatus = 'not_found';
      } else {
        this.savedSearchStatus = 'load_error';
      }
    },
    async submitSavedSearchForm() {
      this.savedSearchFormErrors = {};
      this.savedSearchFormSubmitting = true;

      const json = {
        mode: 'save_search',
        ...this.savedSearchFormFields,
        ...this.sharedFormFields,
        ...this.formFields[this.sharedFormFields.search_type],
      };

      if (this.sharedFormFields.search_type === 'business') {
        json.filter_sic_code = json.filter_sic_code.map((s) => s.split(' ')[0]);
      }

      if (this.savedSearchId !== null) {
        json.saved_search_id = this.savedSearchId;
      }

      const responseData = await this.api({
        url: 'process-search/',
        method: 'POST',
        json,
      });

      this.savedSearchFormSubmitting = false;

      if ([200, 201].includes(responseData.status)) {
        this.savedSearchData = responseData.body;
        this.$set(this.savedSearchData, 'original_json', JSON.stringify(this.getSearchJson()));
        this.savedSearchStatus = 'loaded';

        let snackbarText = 'Your search was successfully ';

        if (responseData.status === 200) {
          snackbarText += 'updated.';
        } else {
          snackbarText += 'saved.';
          await this.$router.replace({ name: 'search', params: { savedSearchId: responseData.body.id } });
        }

        this.savedSearchDialogShowing = false;
        this.savedSearchSnackbarData = {
          showing: true,
          text: snackbarText,
        };
      } else if (responseData.status === 400) {
        this.savedSearchFormErrors = responseData.body;
      } else {
        this.savedSearchFormErrors = {
          non_field_errors: [
            'Unable to communicate with the server. Please check your '
            + 'connection and try again.',
          ],
        };
      }
    },
    getFilterChoicesFromMap(items, searchType, key) {
      return items.map((item) => this.filterChoicesMap[searchType][key][item]);
    },
    stepClicked(stepClicked) {
      if (stepClicked < this.step) {
        this.step = stepClicked;
      }
      if (stepClicked === 2 && this.step1Complete) {
        this.step = stepClicked;
      }
      if (stepClicked === 3 && this.step1Complete) {
        this.step = stepClicked;
      }
      if (stepClicked === 4 && this.step1Complete) {
        this.step = stepClicked;
      }
    },
    async loadCounties() {
      if (this.savedSearchStatus === 'idle' || this.countyChoicesInitialLoadComplete) {
        this.sharedFormFields.counties = [];
      }

      if (this.sharedFormFields.state === 'DC') {
        this.countyChoices = ['The District'];
        this.countiesStatus = 'loaded';
        return;
      }

      this.countiesStatus = 'loading';

      const responseData = await this.api({
        url: 'leads/state_counties/',
        params: {
          state: this.sharedFormFields.state,
        },
      });

      if (responseData.status === 200) {
        this.countyChoicesInitialLoadComplete = true;
        this.countyChoices = responseData.body;
        this.countiesStatus = 'loaded';
      } else {
        this.countiesStatus = 'error';
      }
    },
    async getAutocomplete(field, val) {
      this.autocompleteData[field].loading = true;

      const responseData = await this.api({
        url: `leads/autocomplete_${field}/`,
        params: {
          q: val,
          state: this.sharedFormFields.state,
        },
      });

      this.autocompleteData[field].loading = false;

      if (responseData.status === 200) {
        this.autocompleteData[field].items = responseData.body;

        if (!this.autocompleteData[field].recentSearches.includes(val)) {
          if (this.autocompleteData[field].recentSearches.length >= 10) {
            this.autocompleteData[
              field
            ].recentSearches = this.autocompleteData[field].recentSearches.slice(1);
          }

          this.autocompleteData[field].recentSearches.push(val);
        }
      } else if (responseData.status === 400) {
        this.autocompleteData[field].errors = responseData.body;
      } else {
        this.autocompleteData[field].errors = [
          'Unable to communicate with the server. Please check your '
          + 'connection and try again.',
        ];
      }
    },
    removeZip(item) {
      const index = this.sharedFormFields.zips.indexOf(item);
      if (index >= 0) {
        this.sharedFormFields.zips.splice(index, 1);
      }
    },
    removeTown(item) {
      const index = this.sharedFormFields.towns.indexOf(item);
      if (index >= 0) {
        this.sharedFormFields.towns.splice(index, 1);
      }
    },
    removeSicCode(item) {
      const index = this.formFields.business.filter_sic_code.indexOf(item);
      if (index >= 0) {
        this.formFields.business.filter_sic_code.splice(index, 1);
      }
    },
    getSearchJson() {
      const json = JSON.parse(JSON.stringify({
        ...this.sharedFormFields,
        ...this.formFields[this.sharedFormFields.search_type],
      }));

      json.zips.sort();
      json.towns.sort();
      json.counties.sort();

      if (this.sharedFormFields.search_type === 'consumer') {
        json.filter_gender.sort();
        json.filter_marital_status.sort();
        json.filter_homeowner_status.sort();
        json.filter_age_range.sort();
        json.filter_month_of_birth.sort();
        json.filter_day_of_birth.sort();
        json.filter_net_worth.sort();
        json.filter_income.sort();
        json.filter_credit_rating.sort();
        json.filter_dwell_type.sort();

        if (json.filter_phone_number !== undefined) {
          json.filter_phone_number.sort();
        } else {
          json.filter_phone_number = [];
        }

        json.filter_email_address.sort();
        json.filter_ethnic_group.sort();
        json.filter_religion.sort();
        json.filter_children.sort();
      } else {
        json.filter_sic_code = json.filter_sic_code.map((s) => s.split(' ')[0]);
        json.filter_sic_code.sort();
        json.filter_sales.sort();
        json.filter_employees.sort();
        json.filter_email_address.sort();
      }

      delete json.file_description;
      delete json.records;
      delete json.fields;

      return json;
    },
    async getLeadCount() {
      this.gettingLeadCount = true;

      const json = this.getSearchJson();

      const responseData = await this.api({
        url: 'process-search/',
        method: 'POST',
        json: {
          mode: 'count',
          ...json,
        },
      });

      this.gettingLeadCount = false;

      if (responseData.status === 200) {
        this.leadCount = responseData.body.count;
        this.leadCountConditions = JSON.stringify(json);
      } else {
        if (this.leadCount === null) {
          this.leadCount = 0;
        }

        if (responseData.status !== 403) {
          // eslint-disable-next-line no-alert
          alert(
            'Failed to update the number of leads found. Please check your '
            + 'connection and try again.',
          );
        }
      }
    },
    async download() {
      this.formErrors = {};
      this.downloading = true;

      const json = {
        mode: 'save_download',
        ...this.sharedFormFields,
        ...this.formFields[this.sharedFormFields.search_type],
      };

      if (this.sharedFormFields.search_type === 'business') {
        json.filter_sic_code = json.filter_sic_code.map((s) => s.split(' ')[0]);
      }

      const responseData = await this.api({
        url: 'process-search/',
        method: 'POST',
        json,
      });

      this.downloading = false;

      if (responseData.status === 200) {
        this.$router.push({ name: 'home' });
      } else if (responseData.status === 400) {
        this.formErrors = responseData.body;
      } else {
        this.formErrors = {
          non_field_errors: [
            'Unable to communicate with the server. Please check your '
            + 'connection and try again.',
          ],
        };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  h1 {
    word-break: break-word;
  }

  .v-stepper__step {
    cursor: pointer;
  }

  .v-input--checkbox:first-child {
    margin-top: 5px;
  }

  ::v-deep .v-card__text {
    max-height: 300px;
  }

  .county-checkboxes {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(auto-fill, minmax(8em, 1fr));
    word-break: break-word;

    .v-input--checkbox {
      margin-top: 5px;
    }

    ::v-deep .v-input__slot {
      align-items: start;
    }
  }

  .download-button-and-wait-time-message {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .download-wait-time-message {
    margin-top: 4px;
    max-width: 300px;
    color: rgba(0, 0, 0, 0.6);
    text-align: right;
  }

  .go-to-top-button {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 1;
  }

  @media (min-width: 568px) {
    .checkbox-cols {
      display: grid;
      grid-gap: 16px;
      grid-template-columns: 1fr 1fr;
      max-width: 425px;

      .v-input--checkbox {
        margin-top: 5px;
      }
    }

    .checkbox-cols4 {
      grid-template-columns: repeat(4, 1fr);
    }
  }
</style>
